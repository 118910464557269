import CryptoJS from "crypto-js";

/**
 * Generate authentication headers based on method and path
 */
function generateHeaders({
  clientId = "",
  clientSecret = "",
  path = "",
  method = "GET",
  dateTime = "",
}) {
  const body = {
    // request body
  };

  const requestLine = `${method.toUpperCase()} ${path} HTTP/1.1`;
  const payload = [`date: ${dateTime}`, requestLine].join("\n");
  const { bodyDigest, signature } = hmacSha256Base64(
    clientSecret,
    payload,
    body
  );

  const header = {
    "Content-Type": "application/json",
    Date: dateTime,
    Authorization: `hmac username="${clientId}", algorithm="hmac-sha256", headers="date request-line", signature="${signature}"`,
    Digest: bodyDigest,
  };

  // Set method and path for the request
  const headers = {
    ...header,
    "X-Idempotency-Key": "1234",
  };

  const options = {
    method: method,
    url: `${path}`,
    headers: {
      ...headers,
    },
    body,
  };

  return options;
}

function hmacSha256Base64(
  secret: string,
  message?: any,
  body?: any
): {
  bodyDigest: string;
  signature: string;
} {
  const digest = CryptoJS.HmacSHA256(message, secret);
  const signature = CryptoJS.enc.Base64.stringify(digest);

  let bodyDigest: string = JSON.stringify(body) || "";
  if (body) {
    const bodyLen = Object.keys(body).length;
    if (bodyLen > 0) {
      const bodyHash = CryptoJS.SHA256(body);
      bodyDigest = "SHA-256=" + CryptoJS.enc.Base64.stringify(bodyHash);
    }
  }

  return {
    bodyDigest,
    signature,
  };
}

function convertToListString(permissions: string[]): string {
  // Group permissions by the first part (e.g., "jurnal")
  if (permissions === undefined || permissions.length === 0) {
    return "<ul></ul>";
  }
  const groupedPermissions: { [key: string]: string[] } = permissions.reduce(
    (groups, permission) => {
      const parts = permission.split(":");
      const groupName = parts[0];
      const subPermission = parts.slice(1).join(":");

      if (!groups[groupName]) {
        groups[groupName] = [];
      }
      groups[groupName].push(subPermission);

      return groups;
    },
    {} as { [key: string]: string[] }
  );

  // Start building the HTML string
  let result = "";

  // Iterate through the groups and build the list structure
  for (const groupName in groupedPermissions) {
    // Start a group item for the main group (e.g., Jurnal)
    result += `<ul>\n  <li>${
      groupName.charAt(0).toUpperCase() + groupName.slice(1)
    }`;

    // Add the sublist for the group's permissions (e.g., contacts:read, contacts:create, etc.)
    result += "\n    <ul>";
    groupedPermissions[groupName].forEach((subPermission) => {
      result += `\n      <li>${subPermission}</li>`;
    });
    result += "\n    </ul>";

    // Close the group item
    result += "\n  </li>\n</ul>";
  }

  return result;
}

function convertUnixTimestampToDate(timestamp: number): string {
  // Create a Date object from the Unix timestamp (in seconds)
  const date = new Date(timestamp * 1000); // Multiply by 1000 to convert to milliseconds

  // Options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // Use 24-hour format
  };

  // Convert the Date object to a formatted string
  return date.toLocaleString("en-GB", options); // 'en-GB' gives the desired format (e.g., 9 Aug 2024, 21:30:10)
}

export { generateHeaders, convertToListString, convertUnixTimestampToDate };
